<template>
  <div>
    <Filters
      :value="query.filter"
      @input="handleFilterChange($event)"
      :is-loading="isLoading"
      class="white"
      full-width
    />
    <div
      class="d-flex justify-center flex-wrap"
      v-if="!isLoading"
    >
    </div>
    <List
      need-control-panel
      :is-loading="isLoading"
      :items="items"
      :filter="query.filter"
      @update:filter="handleFilterChange($event)"
      :pagination.sync="query.pagination"
      :sort.sync="query.sort"
      id="AdvertItems"
    />
    <!---->
  </div>
</template>

<script>
import isLogged from '@/components/mixins/isLogged'
import List from '../components/views/Sale/List'
import list from '@/components/mixins/list'
import Filters from '@/components/views/Sale/Filters'

export default {
  mixins: [list, isLogged],
  name: 'Sale',
  components: { List, Filters },
  data () {
    return {
      show: false,
      apiUrl: '/sale',
      query: {
        filter: {},
        pagination: {
          perPage: '100',
          pageNumber: 1
        },
        sort: {
          updated_at: 'DESC',
          photoCount: 'DESC'
        }
      },
      isOpenSnackbar: true
    }
  },
  watch: {
    isLoading: {
      handler () {
        if (this.getHash && !this.isLoading) {
          setTimeout(() => {
            if (document.querySelector(this.getHash)) {
              this.$vuetify.goTo(this.getHash, { duration: 500, easing: 'easeInOutCubic' })
            }
          }, 10)
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
