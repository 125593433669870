<template>
  <div class="sale-row">
    <v-progress-linear
      height="2"
      :indeterminate="isLoading"
      v-if="isLoading"
    ></v-progress-linear>
    <v-container
      fluid
      style="position: relative"
    >
      <ControlPanel
        v-if="needControlPanel && !isLoading"
        :is-loading="isLoading"
        @md-input="setMdValue($event)"
        :sort="sort"
        @update:sort="$emit('update:sort', $event)"
        :pagination="pagination"
        @update:pagination="$emit('update:pagination', $event)"
        :filter="filter"
        @update:filter="$emit('update:filter', $event)"
      />
      <div v-if="!isLoading">
        <AdvertItems
          v-if="isResultExist"
          :items="items.models"
          :md="md"
        />
        <NoResult v-else/>
      </div>
      <Pagination
        :value="pagination"
        @input="updatePagination"
        :total-count="items.count"
        v-if="!isLoading"
      />
    </v-container>
  </div>
</template>

<script>
import ControlPanel from './List/ControlPanel'
import AdvertItems from './List/AdvertItems'
import Pagination from './List/Pagination'
import NoResult from '@/components/views/Sale/Common/NoResult'

export default {
  name: 'List',
  components: { NoResult, Pagination, AdvertItems, ControlPanel },
  props: {
    needControlPanel: Boolean,
    isLoading: Boolean,
    items: Object,
    pagination: Object,
    sort: Object,
    filter: Object
  },
  data () {
    return {
      md: 3,
      show: false
    }
  },
  computed: {
    isResultExist () {
      return this.items && this.items.hasOwnProperty('models') && this.items.models.length
    }
  },
  methods: {
    updatePagination (event) {
      this.$emit('update:pagination', event)
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    },
    setMdValue (value) {
      this.md = value
    }
  }
}
</script>

<style scoped>
  .sale-row {
    min-height: 70vh;
  }
</style>
